/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_PQtmXHdaz",
  aws_user_pools_web_client_id: "u5c7ni8d4lvrivvh09sk7h4pk",
  oauth: {},
  aws_content_delivery_bucket:
    "mmfidawlaauth-20200828202106-hostingbucket-prod",
  aws_content_delivery_bucket_region: "eu-west-1",
  aws_content_delivery_url:
    "http://mmfidawlaauth-20200828202106-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
};

export default awsmobile;

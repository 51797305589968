// Libs
import { useEffect, useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";

// Helpers
import { config } from "../helpers/config";

// APIs
import { getTwitterInteractions } from "../apis/getTwitterInteractions";

// Components
import TableHeader from "../components/tableHeader";
import TweetID from "../components/tweetID";

createTheme("solarized", {
  text: {
    primary: config.style.text.primary,
    secondary: config.style.text.secondary,
  },
  background: {
    default: "#002b36",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#073642",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function TwitterUser(props) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState(true);

  // Route params
  const userId = props.match.params.id;
  const username = props.match.params.name;

  useEffect(() => {
    const settingData = async () => {
      setLoading(true);

      const result = await getTwitterInteractions(userId);

      if (result && result?.status === config.status.ok) {
        setData(result.data);
      }

      setLoading(false);
    };

    settingData();
  }, [userId]);

  const rearangeData = (data) => {
    const dataStructure = data.map((element) => {
      const dateTime = new Date(element.api_twitterTweets_model.createdAt);

      return {
        tweet_id: <TweetID id={element.tweet_id} />,
        createdAt: dateTime.toDateString() /* + " " + dateTime.toTimeString() */,
        favorite: element.favourite ? "True" : "False",
        retweet: element.retweet ? "True" : "False",
        content: element.api_twitterTweets_model.text,
      };
    });

    return dataStructure;
  };

  const columns = useMemo(
    () => [
      {
        name: "Tweet ID",
        selector: "tweet_id",
      },
      {
        name: "Date",
        selector: "createdAt",
        sortable: true,
      },
      {
        name: "Favorite",
        selector: "favorite",
      },
      {
        name: "Retweet",
        selector: "retweet",
      },
      {
        name: "Tweet Content",
        selector: "content",
        grow: 2,
      },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      theme={selected ? "solarized" : ""}
      data={rearangeData(data)}
      pagination={true}
      noHeader={true}
      subHeader
      progressPending={isLoading}
      subHeaderComponent={
        <TableHeader
          selected={selected}
          toggleSelected={() => {
            setSelected(!selected);
          }}
          title={username}
        />
      }
    />
  );
}

export default TwitterUser;

// Libs
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

// Helpers
import { config } from "./helpers/config";

// Components
import Sidebar from "./components/sidebar";
import CustomAmplifyAuthenticator from "./components/CustomAmplifyAuthenticator";
import FilterForm from "./components/filterForm";

// Pages
import FacebookAnalytics from "./pages/FacebookAnalytics";
import FacebookUser from "./pages/FacebookUser";
import TwitterAnalytics from "./pages/TwitterAnalytics";
import TwitterUser from "./pages/TwitterUser";

// APIs
import { getFacebookUsers } from "./apis/getFacebookUsers";
import { getTwitterUsers } from "./apis/getTwitterUsers";

const SocialMediaAnalytics = () => {
  const [facebookData, setFacebookData] = useState([]);
  const [displayedPage, setDisplayedPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isTweetsLoading, setTweetsLoading] = useState(false);
  const [twitterData, setTwitterData] = useState([]);

  useEffect(() => {
    setTweets();
  }, []);

  const settingData = async (filter) => {
    setLoading(true);

    const result = await getFacebookUsers(filter);

    if (result && result?.status === config.status.ok) {
      setFacebookData(result.data);
    }

    setLoading(false);
  };

  const setTweets = async () => {
    setTweetsLoading(true);

    const result = await getTwitterUsers();
    console.log(result);
    if (result && result?.status === config.status.ok) {
      setTwitterData(result.data);
    }

    setTweetsLoading(false);
  };

  return (
    <BrowserRouter>
      <Sidebar />
      <FilterForm submitValues={settingData} />
      <Switch>
        <Route path={config.paths.facebookAnalytics} exact>
          <FacebookAnalytics
            data={facebookData}
            isLoading={isLoading}
            displayedPage={displayedPage}
            updateDisplayedPage={setDisplayedPage}
          />
        </Route>
        <Route
          path={`${config.paths.facebookUser}/:name/:id`}
          component={FacebookUser}
          exact
        />
        <Route path={config.paths.twitterAnalytics} exact>
          <TwitterAnalytics
            data={twitterData}
            isTweetsLoading={isTweetsLoading}
          />
        </Route>
        <Route
          path={`${config.paths.twitterUser}/:name/:id`}
          component={TwitterUser}
          exact
        />
      </Switch>
    </BrowserRouter>
  );
};

function App() {
  const [auth, setAuth] = React.useState({
    groups: [],
    authState: null,
  });

  // Authentication
  React.useEffect(() => {
    if (auth.state === null) {
      Auth.currentAuthenticatedUser()
        .then((res, err) => {
          setAuth({
            groups:
              res?.signInUserSession?.idToken?.payload["cognito:groups"] || [],
            state: AuthState.SignedIn,
          });
        })
        .catch((err) => {});
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      let newState = {
        groups: [],
        authState: nextAuthState,
      };

      if (authData?.signInUserSession?.idToken?.payload["cognito:groups"]) {
        newState.groups =
          authData.signInUserSession.idToken.payload["cognito:groups"];
      }

      setAuth(newState);
    });
  }, [auth.state]);

  const retry = async () => {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

    // Refresh session so that if the user was made an Admin, then he/she
    // would get access and doesn't need to logout and then log back in
    currentAuthenticatedUser.refreshSession(
      currentAuthenticatedUser.signInUserSession.refreshToken,
      async (error, refreshedSession) => {
        setAuth((prevState) => ({
          ...prevState,
          groups: refreshedSession?.idToken?.payload["cognito:groups"],
        }));
      }
    );
  };

  const createUI = () => {
    let body = (
      <div className="global-error">
        <div>Not enough permissions</div>
        <br />
        <button onClick={() => retry()}>Retry</button>
      </div>
    );

    if (auth.groups && auth.groups.includes(config.auth.groups.admin)) {
      body = <SocialMediaAnalytics />;
    }

    return body;
  };

  return auth.authState === AuthState.SignedIn ? (
    createUI()
  ) : (
    <CustomAmplifyAuthenticator />
  );
}

export default App;

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../helpers/config";
import { slide as Menu } from "react-burger-menu";

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "15px",
    top: "15px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#002b36",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#002b36",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
    display: "flex",
    flexDirection: "column",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
  menuItem: {
    cursor: "pointer",
    color: config.style.text.primary,
  },
};

const Sidebar = () => {
  const history = useHistory();
  const [open, setOpen] = useState(true);

  const redirect = (path) => {
    history.push(path);
    setOpen(false);
  };

  return (
    <Menu isOpen={open} styles={styles}>
      <div
        id="facebook-analytics"
        style={styles.menuItem}
        onClick={() => redirect(config.paths.facebookAnalytics)}
      >
        Facebook Analytics
      </div>
      <div
        id="twitter-analytics"
        style={styles.menuItem}
        onClick={() => redirect(config.paths.twitterAnalytics)}
      >
        Twitter Analytics
      </div>
    </Menu>
  );
};

export default Sidebar;

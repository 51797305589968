import DatePicker from "react-datepicker";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

function FilterForm(props) {
  let [startDate, setStartDate] = useState();
  let [endDate, setEndDate] = useState();
  let [limit] = useState();

  //   const setLimit = (e) => {
  //     limit = e.target.value;
  //   };
  const submitForm = (e) => {
    e.preventDefault();
    let values = {};
    let day = 1;
    let month = 1;
    if (startDate) {
      day = startDate.getDate();
      month = startDate.getMonth() + 1;
      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;
      values.from =
        startDate.getFullYear() + "-" + month + "-" + day;
    }
    if (endDate) {
      day = endDate.getDate();
      month = endDate.getMonth() + 1;
      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;
      values.to =
        endDate.getFullYear() + "-" + month + "-" + day;
    }
    if (limit) values.limit = limit;
    if (props.submitValues) props.submitValues(values);
  };

  return (
    <form onSubmit={submitForm} autoComplete="off" style={styles.form}>
      {/* <div>
        <label htmlFor="limitInput" style={{ fontSize: "small" }}>
          Set Limit :{" "}
        </label>
        <input
          name="limitInput"
          type="number"
          onChange={setLimit}
          style={{ width: "75px" }}
          min="1"
          step="1"
        />
      </div> */}
      <div>
        <DatePicker
          name="from"
          selected={startDate}
          onChange={setStartDate}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select start date"
          isClearable
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </div>
      <div>
        <label style={{ marginLeft: "10px", marginRight: "10px" }}> to </label>
      </div>
      <div>
        <DatePicker
          name="to"
          selected={endDate}
          onChange={setEndDate}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select end date"
          isClearable
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      <div>
        <input type="submit" value="Load Data" style={styles.button} />
      </div>
    </form>
  );
}

const styles = {
  button: {
    background: "#002B49",
    color: "white",
    marginLeft: "10px",
    cursor: "pointer",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    padding: 20,
  },
};

export default FilterForm;

import { config } from "../helpers/config";

export const getFacebookUsers = async (filter) => {
  try {
    let data = JSON.stringify(filter);

    const response = await fetch(`${config.apiUrl}/facebook/users`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: data,
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

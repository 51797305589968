const TwitterUsername = ({ username }) => {
  const url = `https://twitter.com/${username}`;

  const openUrl = () => {
    window.open(url, "_blank");
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={() => openUrl()}>
      {username}
    </div>
  );
};

export default TwitterUsername;

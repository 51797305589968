// Libs
import { useEffect, useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";

// Helpers
import { config } from "../helpers/config";
import { filter } from "../helpers";

// APIs
import { getFacebookInteractions } from "../apis/getFacebookInteractions";

// Components
import TableHeader from "../components/tableHeader";
import FacebookID from "../components/facebookID";
import FacebookComment from "../components/facebookComment";

createTheme("solarized", {
  text: {
    primary: config.style.text.primary,
    secondary: config.style.text.secondary,
  },
  background: {
    default: "#002b36",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#073642",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function FacebookUser(props) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [selected, setSelected] = useState(true);

  // Route params
  const userId = props.match.params.id;
  const username = props.match.params.name;

  useEffect(() => {
    const settingData = async () => {
      setLoading(true);

      const result = await getFacebookInteractions(userId);

      if (result && result?.status === config.status.ok) {
        setData(result.data);
      }

      setLoading(false);
    };

    settingData();
  }, [userId]);

  const rearangeData = (data) => {
    const dataStructure = data.map((element) => {
      const dateTime = new Date(element.createdAt);

      return {
        id: element.id,
        createdAt: dateTime.toDateString() /* + " " + dateTime.toTimeString() */,
        type: element.type,
        postId: <FacebookID id={element.postId} />,
        parentId: <FacebookID id={element.parentId} />,
        comment: (
          <FacebookComment id={element.commentId} text={element.metadata} />
        ),
      };
    });

    return dataStructure;
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        grow: 0.1,
      },
      {
        name: "Date",
        selector: "createdAt",
        sortable: true,
        grow: 0.5,
      },
      {
        name: "Interaction Type",
        selector: "type",
        sortable: true,
        grow: 0.3,
      },
      {
        name: "Post ID",
        selector: "postId",
        sortable: false,
      },
      {
        name: "Parent ID",
        selector: "parentId",
        sortable: false,
      },
      {
        name: "Comment",
        selector: "comment",
        sortable: false,
        wrap: true,
      },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      theme={selected ? "solarized" : ""}
      data={rearangeData(filter(data, filterInput, "metadata"))}
      pagination={true}
      noHeader={true}
      subHeader
      progressPending={isLoading}
      subHeaderComponent={
        <TableHeader
          selected={selected}
          toggleSelected={() => {
            setSelected(!selected);
          }}
          onChange={(event) => setFilterInput(event.target.value)}
          filterPlaceholder="filter by comment"
          title={username}
        />
      }
    />
  );
}

export default FacebookUser;

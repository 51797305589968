import './index.css';

const ThemeToggler = ({toggleSelected,selected}) => (
    <div className="toggle-container" onClick={toggleSelected}>
        <div className={`dialog-button ${selected ? "" : "disabled"}`}>
            {selected ? "DARK" : "LIGHT"}
        </div>
    </div>
);

export default ThemeToggler;
const TweetID = ({ id }) => {
  const url = `https://twitter.com/mmfidawla/status/${id}`;

  const openUrl = () => {
    window.open(url, "_blank");
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={() => openUrl()}>
      {id}
    </div>
  );
};

export default TweetID;

import classes from "./index.module.css";

const FacebookComment = ({ text, id }) => {
  const url = `https://facebook.com/${id}`;

  const openUrl = () => {
    window.open(url, "_blank");
  };

  return (
    id && <div className={classes.facebookComment} onClick={() => openUrl()}>
      {text}
    </div>
  );
};

export default FacebookComment;

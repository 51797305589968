import { config } from "../helpers/config";

export const getTwitterUsers = async () => {
  try {
    const response = await fetch(`${config.apiUrl}/twitter/users`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Libs
import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useHistory } from "react-router-dom";

// Helpers
import { config } from "../helpers/config";
import { filter } from "../helpers";

// Components
import TableHeader from "../components/tableHeader";
import TwitterUsername from "../components/twitterUsername";

createTheme("solarized", {
  text: {
    primary: config.style.text.primary,
    secondary: config.style.text.secondary,
  },
  background: {
    default: "#002b36",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#073642",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const rearrangeData = (data, history) => {
  const dataStructure = data.map((element) => {
    return {
      id: element.id,
      name: (
        <div
          style={styles.username}
          onClick={() => goToTwitterUserPage(element.name, element.id, history)}
        >
          {element.name}
        </div>
      ),
      screen_name: <TwitterUsername username={element.screen_name} />,
      verified: element.verified ? "Yes" : "No",
      followers_count: element.followers_count,
      statuses_count: element.statuses_count,
      mmfid_favourites: element.mmfid_favourites,
      mmfid_retweets: element.mmfid_retweets,
      mmfid_totalInteractions:
        element.mmfid_favourites + element.mmfid_retweets,
    };
  });

  return dataStructure;
};

const styles = {
  username: {
    cursor: "pointer",
  },
};

const goToTwitterUserPage = (username, userId, history) => {
  history.push(`${config.paths.twitterUser}/${username}/${userId}`);
};

function TwitterAnalytics({ data, isTweetsLoading }) {
  const history = useHistory();
  const [selected, setSelected] = useState(true);
  const [filterInput, setFilterInput] = useState("");

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Screen Name",
      selector: "screen_name",
      sortable: true,
    },
    {
      name: "Verified",
      selector: "verified",
      sortable: true,
    },
    {
      name: "Followers Count",
      selector: "followers_count",
      sortable: true,
    },
    {
      name: "Statuses Count",
      selector: "statuses_count",
      sortable: true,
    },
    {
      name: "Favorites",
      selector: "mmfid_favourites",
      sortable: true,
    },
    {
      name: "Retweets",
      selector: "mmfid_retweets",
      sortable: true,
    },
    {
      name: "Total Interactions",
      selector: "mmfid_totalInteractions",
      sortable: true,
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        theme={selected ? "solarized" : ""}
        data={rearrangeData(filter(data, filterInput, "name"), history)}
        pagination={true}
        noHeader={true}
        subHeader
        defaultSortField="mmfid_totalInteractions"
        defaultSortAsc={false}
        progressPending={isTweetsLoading}
        subHeaderComponent={
          <TableHeader
            selected={selected}
            toggleSelected={() => {
              setSelected(!selected);
            }}
            onChange={(event) => setFilterInput(event.target.value)}
            filterPlaceholder="filter by name"
            title="Twitter Analytics"
          />
        }
      />
    </div>
  );
}

export default TwitterAnalytics;

// Libs
import React, { useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Moment from 'moment';

// Helpers
import { config } from "../helpers/config";
import { filter } from "../helpers";

// Components
import TableHeader from "../components/tableHeader";
import FacebookID from "../components/facebookID";

createTheme("solarized", {
  text: {
    primary: config.style.text.primary,
    secondary: config.style.text.secondary,
  },
  background: {
    default: "#002b36",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#073642",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const styles = {
  username: {
    cursor: "pointer",
  },
};

const goToFacebookUserPage = (username, userId, history) => {
  history.push(`${config.paths.facebookUser}/${username}/${userId}`);
};

const countReaction = (collection, type = "all") => {
  if (!collection) return 0;

  let counter = 0;
  collection.forEach((element) => {
    if (element.type === type || type === "all") {
      counter++;
    }
  });

  return counter;
};


const getInteractionDate = ( collection , type = "all") => {
    if (!collection) return 0;
    let smallest = '';
    let largest = '';

    collection.forEach((element) => {
        if (element.type === type || type === "all") {
            if (smallest === '' && largest === '') {
                smallest = element.createdAt;
                largest = element.createdAt;
            } else {
                if(element.createdAt < smallest) smallest = element.createdAt;
                if(element.createdAt > largest ) largest =element.createdAt;
            }
        }
    });


    return {
        first: smallest,
        last: largest
    };
};

const rearrangeData = (data, history) => {
  const dataStructure = data.map((element) => {
    let interactionsDate = getInteractionDate(element.api_facebookInteractions_models);

    return {
      // N.B: Fixed conflict error between row element id and User Link ID
      id: element.id,
      userid: <FacebookID id={element.id} />,
      name: (
        <div
          style={styles.username}
          onClick={() =>
            goToFacebookUserPage(element.name, element.id, history)
          }
        >
          {element.name}
        </div>
      ),
      totalInteractions: "bla",
      love: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.love
      ),
      like: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.like
      ),
      care: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.care
      ),
      haha: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.haha
      ),
      wow: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.wow
      ),
      sad: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.sad
      ),
      angry: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.angry
      ),
      comment: countReaction(
        element.api_facebookInteractions_models,
        config.interactions.type.comment
      ),
      firstInteraction: interactionsDate.first,
      lastInteraction: interactionsDate.last,
      total: countReaction(element.api_facebookInteractions_models),
    };
  });

  return dataStructure;
};


function FacebookAnalytics({
  data,
  isLoading,
  updateDisplayedPage,
  displayedPage,
}) {
  const history = useHistory();
  const [filterInput, setFilterInput] = useState("");
  const [selected, setSelected] = useState(true);

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: "userid",
        sortable: true,
        grow: 2,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        grow: 2,
      },
      {
        name: "Likes",
        selector: config.interactions.type.like,
        sortable: true,
        width: "50px",
      },
      {
        name: "Love",
        selector: config.interactions.type.love,
        sortable: true,
        width: "50px",
      },
      {
        name: "Care",
        selector: config.interactions.type.care,
        sortable: true,
        width: "50px",
      },
      {
        name: "Haha",
        selector: config.interactions.type.haha,
        sortable: true,
        width: "50px",
      },
      {
        name: "Wow",
        selector: config.interactions.type.wow,
        sortable: true,
        width: "50px",
      },
      {
        name: "Sad",
        selector: config.interactions.type.sad,
        sortable: true,
        width: "50px",
      },
      {
        name: "Angry",
        selector: config.interactions.type.angry,
        sortable: true,
        width: "50px",
      },
      {
        name: "Comments",
        selector: config.interactions.type.comment,
        sortable: true,
        width: "100px",
      },
      {
        name: "First Interaction",
        selector: "firstInteraction",
        sortable: true,
        grow: 2,
        format: function (row,index) {
           return Moment(row.firstInteraction).format('ddd DD MMM YYYY hh:mm:ss');
        },
      },
      {
        name: "Last Interaction",
        selector: "lastInteraction",
        sortable: true,
        grow: 2,
        format: function (row,index) {
          return Moment(row.lastInteraction).format('ddd DD MMM YYYY hh:mm:ss');
        }
      },
      {
        name: "Total Interactions",
        selector: "total",
        sortable: true,
      },
    ],
    []
  );

  return (
    <div>
      <DataTable
        columns={columns}
        theme={selected ? "solarized" : ""}
        data={rearrangeData(filter(data, filterInput, "name"), history)}
        pagination={true}
        noHeader={true}
        subHeader
        defaultSortField="total"
        defaultSortAsc={false}
        progressPending={isLoading}
        paginationDefaultPage={displayedPage}
        onChangePage={(page) => updateDisplayedPage(page)}
        subHeaderComponent={
          <TableHeader
            selected={selected}
            toggleSelected={() => {
              setSelected(!selected);
            }}
            onChange={(event) => setFilterInput(event.target.value)}
            filterPlaceholder="filter by name"
            title="Facebook Analytics"
          />
        }
      />
    </div>
  );
}

export default FacebookAnalytics;

import { config } from "../helpers/config";

export const getTwitterInteractions = async (user_id) => {
  try {
    const response = await fetch(
      `${config.apiUrl}/twitter/interactions/${user_id}`
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  status: {
    error: "error",
    ok: "ok",
  },
  interactions: {
    type: {
      like: "like",
      love: "love",
      care: "care",
      haha: "haha",
      wow: "wow",
      sad: "sad",
      angry: "angry",
      comment: "comment",
    },
  },
  style: {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
  },
  paths: {
    facebookAnalytics: "/facebook",
    facebookUser: "/facebook/user",
    twitterAnalytics: "/twitter",
    twitterUser: "/twitter/user",
  },
  auth: {
    groups: {
      admin: "Admin",
    },
  },
};

import { config } from "../../helpers/config";
import SearchBox from "../searchBox";
import ThemeToggler from "../themeToggler";
import React from "react";

const TableHeader = ({
  selected,
  toggleSelected,
  onChange,
  filterPlaceholder,
  title,
  submit,
}) => (
  <div>
    <div style={styles.header}>
      <h1>{title}</h1>
    </div>

    <div style={styles.container}>
      <div style={styles.leftContainer}></div>
      <div style={styles.rightContainer}>
        <div style={styles.themeToggler}>
          <ThemeToggler selected={selected} toggleSelected={toggleSelected} />
        </div>
        {filterPlaceholder ? (
          <SearchBox onChange={onChange} placeholder={filterPlaceholder} />
        ) : (
          <></>
        )}
      </div>
    </div>
  </div>
);

const styles = {
  header: {
    width: "100vw",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "40px",
    marginTop: "20px",
    color: config.style.text.primary,
  },
  container: {
    width: "100vw",
  },
  rightContainer: {
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  leftContainer: {
    float: "left",
    overflow: "hidden",
    marginLeft: "50px",
    color: "#FFFFFF",
  },
  themeToggler: {
    marginRight: "10px",
  },
};

export default TableHeader;

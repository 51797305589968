import { config } from "../helpers/config";

export const getFacebookInteractions = async (userId) => {
  try {
    const response = await fetch(`${config.apiUrl}/facebook/interactions`, {
      method: "POST",
      headers: {
        // "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};
